import Phaser from 'phaser';

export default class Start extends Phaser.Scene {
	constructor() {
		super({ key: 'Start' });
		console.log('Start scene initialized');
	}

	preload() {
		this.load.image('background', 'assets/background.png');
		this.load.image('create', 'assets/create.png');
		this.load.image('join', 'assets/join.png');
		this.load.image('solo', 'assets/solo.png');
	}

	create() {
		let img = this.add.image(640, 360, 'background');
		img.setDisplaySize(this.game.config.width, this.game.config.height);

		const inputName = document.createElement('input');
		const inputValue = localStorage.getItem('playerName') || '';
		inputName.type = 'text';
		inputName.value = inputValue;
		inputName.placeholder = 'Enter your name';
		inputName.className = 'name-input';

		document.body.appendChild(inputName);

		let createButton = this.add.sprite(640, 330, 'create').setInteractive();
		createButton.on('pointerdown', () => {
			const playerName = inputName.value.trim();

			this.scene.launch('CreateLobby', { playerName: playerName });
			this.scene.pause('Start');

			localStorage.setItem('playerName', playerName);
			document.body.removeChild(inputName);
		});

		let joinButton = this.add.sprite(640, 400, 'join').setInteractive();
		joinButton.on('pointerdown', () => {
			const playerName = inputName.value.trim();

			this.scene.launch('JoinLobby', { playerName: playerName });
			this.scene.pause('Start');

			localStorage.setItem('playerName', playerName);
			document.body.removeChild(inputName);
		});

		let playSoloButton = this.add.sprite(640, 470, 'solo').setInteractive();
		playSoloButton.on('pointerdown', () => {
			const playerName = inputName.value.trim();

			this.scene.launch('Game', { solo: true });
			this.scene.pause('Start');

			localStorage.setItem('playerName', playerName);
			document.body.removeChild(inputName);
		});

		const bestScore = localStorage.getItem('bestScore') || 0;
		this.add.text(490, 540, `Best Score: ${bestScore}`, { fontSize: '40px', fill: '#000' });
	}
}
