import Phaser from 'phaser';
import io from 'socket.io-client';
import { SERVER_URL } from './utils';

export default class CreateLobby extends Phaser.Scene {
	constructor() {
		super({ key: 'CreateLobby' });
		console.log('CreateLobby scene initialized');
	}

	init(data) {
		this.playerName = data.playerName;
	}

	preload() {
		this.load.image('background', 'assets/background.png');
		this.load.image('waiting', 'assets/waiting.png');
		this.load.image('back', 'assets/back.png');
	}

	create() {
		let img = this.add.image(640, 360, 'background');
		img.setDisplaySize(this.game.config.width, this.game.config.height);
		this.add.sprite(640, 310, 'waiting').setScale(0.3);

		this.playerText = this.add.text(10, 10, this.playerName || 'Player 1', { fontSize: '40px', fill: '#000' });

		let back = this.add.sprite(40, 80, 'back').setInteractive().setScale(0.1);
		back.on('pointerdown', () => {
			this.socket.disconnect();
			this.scene.launch('Start');
			this.scene.stop('CreateLobby');
		});

		// Socket communication
		this.socket = io(SERVER_URL);

		this.socket.on('connect', () => {
			console.log('Connected to server');
		});

		this.socket.on('connected', (id) => {
			this.playerId = id;
			this.socket.emit('createLobby', `${this.playerName || 'Player 1'}'s Lobby`, this.playerName);
		});

		this.socket.on('lobbyCreated', (lobbyId) => {
			this.add.text(10, 120, `Lobby ID: ${lobbyId}`, { fontSize: '30px', fill: '#000' });
		});

		this.socket.on('message', (data) => {
			console.log(`Received message: ${data}`);
			const [message, enemyName] = data.split(';');
			if (message === 'Start') {
				this.scene.launch('Game', { socket: this.socket, playerId: this.playerId, enemyName });
				this.scene.pause('CreateLobby');
				this.scene.stop('Start');
			}
		});
	}
}
