import Phaser from 'phaser';

export default class Game extends Phaser.Scene {
	constructor() {
		super({ key: 'Game' });
		console.log('Game scene initialized');
	}

	init(data) {
		this.score = 0;
		this.enemyScore = 0;
		this.solo = data.solo;
		this.socket = data.socket;
		this.playerId = data.playerId;
		this.enemyName = data.enemyName || 'Player 1';
		this.lost = false;
		this.difficultyFunction = (seconds) => {
			const x = seconds % 150;
			if (x < 15) {
				return 50;
			} else if (x >= 15 && x < 30) {
				return 70;
			} else if (x >= 30 && x < 60) {
				return 90;
			} else if (x >= 60 && x < 83) {
				return 70;
			} else if (x >= 83 && x < 128) {
				return 90;
			} else if (x >= 128 && x <= 149) {
				return 50;
			} else {
				return 50;
			}
		};
		this.cursors = this.input.keyboard.createCursorKeys();
		this.keyW = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.W);
		this.keyA = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.A);
		this.keyS = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.S);
		this.keyD = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.D);
	}

	preload() {
		this.load.image('background', 'assets/background.png');
		this.load.image('ground', 'assets/ground.png');
		this.load.image('enemy', 'assets/enemy.png');
		this.load.image('dumbbell', 'assets/dumbbell.png');
		this.load.spritesheet('player', 'assets/player.png', { frameWidth: 30, frameHeight: 40, spacing: 2 });
		this.load.audio('song', 'assets/bossfight.mp3');
	}

	create() {
		let background = this.add.image(640, 360, 'background');
		background.setDisplaySize(this.game.config.width, this.game.config.height);

		this.scoreText = this.add.text(10, 10, 'Score: 0', { fontSize: '40px', fill: '#000' });
		this.enemyScoreText = !this.solo
			? this.add.text(10, 50, `${this.enemyName}'s Score: 0`, { fontSize: '20px', fill: '#000' })
			: '';

		let platforms = this.physics.add.staticGroup();
		platforms.create(640, 672, 'ground');
		platforms.create(640, 612, 'ground');
		platforms.create(640, 552, 'ground');

		this.player = this.physics.add.sprite(100, 350, 'player');
		this.player.setCollideWorldBounds(true);
		this.physics.add.collider(this.player, platforms);
		this.removeAnims();
		this.addAnims('player');

		this.enemy = this.add.image(1262, 480, 'enemy').setScale(0.6);
		this.addEnemyAnims();

		this.music = this.sound.add('song', { loop: true });
		this.music.setVolume(0.1);
		this.music.play();

		this.dumbbells = this.physics.add.group();
		this.physics.add.overlap(this.player, this.dumbbells, this.hitDumbbell, null, this);
		this.startInterval();

		this.socket?.on('enemy', this.handleScoreUpdate);
	}

	update() {
		this.checkOutOfBounds();
		const secondsPassed = (this.time.now - this.time.startTime) / 1000;
		const difficulty = this.difficultyFunction(secondsPassed) / 50;

		if (this.cursors.left.isDown || this.keyA.isDown) {
			this.player.setVelocityX(-400 * difficulty);
			this.player.anims.play('left', true);
		} else if (this.cursors.right.isDown || this.keyD.isDown) {
			this.player.setVelocityX(400 * difficulty);
			this.player.anims.play('right', true);
		} else {
			this.player.setVelocityX(0);
			this.player.anims.play('turn');
		}

		if ((this.cursors.up.isDown || this.keyW.isDown) && this.player.body.touching.down) {
			this.player.setVelocityY(-600);
		} else if (this.cursors.down.isDown || this.keyS.isDown) {
			this.player.setVelocityY(550);
		}

		if (this.player.x > 1090) {
			this.gameOver();
		}
	}

	handleScoreUpdate = (score) => {
		console.log(`Received score: ${score}`);
		this.enemyScore = score;
		this.enemyScoreText.setText(`${this.enemyName}'s Score: ${this.enemyScore}`);
	};

	startInterval() {
		const that = this;
		let intervalTime = 1000; // Initial interval time in milliseconds
		let isPaused = false;

		function updateInterval() {
			// Calculate difficulty based on secondsPassed
			const secondsPassed = (that.time.now - that.time.startTime) / 1000;
			const difficulty = that.difficultyFunction(secondsPassed) / 40;

			// Decrease the interval time
			intervalTime = 1000 / difficulty;

			// Clear the current interval if not paused
			if (!isPaused) {
				clearInterval(that.intervalID);

				// Set a new interval with the updated interval time
				that.intervalID = setInterval(() => {
					that.throwDumbbell();
				}, intervalTime);
			}
		}

		// Start the initial interval
		this.intervalID = setInterval(() => {
			this.throwDumbbell();
		}, intervalTime);

		// Update the interval every second
		this.updateIntervalID = setInterval(() => {
			updateInterval();
		}, 1000);

		// Handle visibility change
		document.addEventListener('visibilitychange', () => {
			if (document.visibilityState === 'hidden') {
				// Tab switched, pause the interval
				clearInterval(that.intervalID);
				isPaused = true;
			} else if (!that.lost) {
				// Tab back in focus, resume the interval if game is not over
				isPaused = false;
				updateInterval();
			}
		});
	}

	stopInterval() {
		clearInterval(this.intervalID);
		clearInterval(this.updateIntervalID);
	}

	removeAnims() {
		this.anims.remove('left');
		this.anims.remove('turn');
		this.anims.remove('right');
	}

	addAnims(key) {
		this.anims.create({
			key: 'left',
			frames: this.anims.generateFrameNumbers(key, { start: 0, end: 3 }),
			frameRate: 15,
			repeat: -1
		});
		this.anims.create({
			key: 'turn',
			frames: [{ key, frame: 4 }],
			frameRate: 30
		});
		this.anims.create({
			key: 'right',
			frames: this.anims.generateFrameNumbers(key, { start: 5, end: 8 }),
			frameRate: 15,
			repeat: -1
		});
	}

	addEnemyAnims() {
		let enemyEntryAnim = {
			targets: this.enemy,
			x: 1182,
			y: 480,
			duration: 500,
			ease: 'Linear'
		};
		this.enemyThrowAnim1 = {
			targets: this.enemy,
			x: 1172,
			y: 480,
			duration: 100,
			ease: 'Linear'
		};
		this.enemyThrowAnim2 = {
			targets: this.enemy,
			x: 1182,
			y: 480,
			duration: 100,
			ease: 'Linear'
		};
		this.tweens.add(enemyEntryAnim);
	}

	throwDumbbell() {
		const secondsPassed = (this.time.now - this.time.startTime) / 1000;
		const difficulty = this.difficultyFunction(secondsPassed) / 50;

		let velocity = this.randomIntFromInterval(300, 500) * difficulty;
		let y = this.randomIntFromInterval(340, 500);

		let dumbbell = this.dumbbells.create(1080, y, 'dumbbell').setScale(0.3);
		dumbbell.setVelocityX(-velocity);
		dumbbell.setGravityY(-1250);

		this.tweens.add(this.enemyThrowAnim1).on('complete', () => this.tweens.add(this.enemyThrowAnim2), this);
	}

	randomIntFromInterval(min, max) {
		return Math.floor(Math.random() * (max - min + 1) + min);
	}

	checkOutOfBounds() {
		let children = this.dumbbells.getChildren();

		for (let i = 0; i < children.length; i++) {
			let child = children[i];
			let bounds = child.getBounds();

			if (child.isDisabled) {
				continue;
			}

			if (bounds.right < 0) {
				child.isDisabled = true;
				child.disableBody(true, true);

				this.score += 1;
				this.scoreText.setText(`Score: ${this.score}`);
				this.socket?.emit('score', { playerId: this.playerId, score: this.score });
			}
		}
	}

	hitDumbbell() {
		this.gameOver();
	}

	gameOver() {
		this.lost = true;
		this.music.pause();
		this.stopInterval();

		this.socket?.emit('done', this.playerId);
		this.socket?.off('enemy', this.handleScoreUpdate);
		this.scene.launch('GameOver', {
			solo: this.solo,
			socket: this.socket,
			score: this.score,
			enemyScore: this.enemyScore,
			enemyName: this.enemyName
		});
		this.scene.stop('Game');
	}
}
