import Phaser from 'phaser';

export default class GameOver extends Phaser.Scene {
	constructor() {
		super({ key: 'GameOver' });
		console.log('GameOver scene initialized');
	}

	init(data) {
		this.solo = data.solo;
		this.socket = data.socket;
		this.score = data.score;
		this.enemyScore = data.enemyScore;
		this.enemyName = data.enemyName;
	}

	preload() {
		this.load.image('background', 'assets/background.png');
		this.load.image('play', 'assets/play.png');
	}

	create() {
		let img = this.add.image(640, 360, 'background');
		img.setDisplaySize(this.game.config.width, this.game.config.height);

		this.scoringText = this.add.text(
			450,
			200,
			!this.solo
				? `Game Over!\nYour Score: ${this.score}\n${this.enemyName}'s Score: ${this.enemyScore}`
				: `Game Over!\nYour Score: ${this.score}`,
			{
				fontSize: '50px',
				fill: '#000'
			}
		);
		this.socket?.on('enemy', this.handleScoreUpdate);

		let playButton = this.add.sprite(640, 380, 'play').setInteractive();
		playButton.on('pointerdown', (e) => {
			this.socket?.off('enemy', this.handleScoreUpdate);
			this.scene.launch('Start');
			this.scene.stop('GameOver');
			this.scene.stop('CreateLobby');
			this.scene.stop('JoinLobby');
		});

		const bestScore = +localStorage.getItem('bestScore') || 0;
		if (this.score > bestScore) {
			localStorage.setItem('bestScore', this.score);
		}
	}

	handleScoreUpdate = (score) => {
		console.log(`Received score: ${score}`);
		this.enemyScore = score;
		this.scoringText.setText(
			`Game Over!\nYour Score: ${this.score}\n${this.enemyName}'s Score: ${this.enemyScore}`
		);
	};
}
