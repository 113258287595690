import Phaser from 'phaser';
import Start from './Start';
import CreateLobby from './CreateLobby';
import JoinLobby from './JoinLobby';
import Game from './Game';
import GameOver from './GameOver';

const config = {
	type: Phaser.AUTO,
	width: 1280,
	height: 720,
	physics: {
		default: 'arcade',
		arcade: {
			gravity: { y: 1250 },
			debug: false
		}
	},
	scene: [Start, CreateLobby, JoinLobby, Game, GameOver]
};

const game = new Phaser.Game(config);
game.scene.start('Start');
