import Phaser from 'phaser';
import io from 'socket.io-client';
import { SERVER_URL } from './utils';

export default class JoinLobby extends Phaser.Scene {
	constructor() {
		super({ key: 'JoinLobby' });
		console.log('JoinLobby scene initialized');
	}

	init(data) {
		this.playerName = data.playerName;
	}

	preload() {
		this.load.image('background', 'assets/background.png');
		this.load.image('back', 'assets/back.png');
	}

	create() {
		let img = this.add.image(640, 360, 'background');
		img.setDisplaySize(this.game.config.width, this.game.config.height);

		this.playerText = this.add.text(10, 10, this.playerName || 'Player 1', { fontSize: '40px', fill: '#000' });

		let lobbyListContainer = document.createElement('div');
		lobbyListContainer.id = 'lobby-list';
		document.body.appendChild(lobbyListContainer);

		let back = this.add.sprite(40, 80, 'back').setInteractive().setScale(0.1);
		back.on('pointerdown', () => {
			this.socket.disconnect();
			this.scene.launch('Start');
			this.scene.stop('JoinLobby');
			document.body.removeChild(lobbyListContainer);
		});

		// Socket communication
		this.socket = io(SERVER_URL);

		this.socket.on('connect', () => {
			console.log('Connected to server');
		});

		this.socket.on('connected', (id) => {
			this.playerId = id;
		});

		this.socket.on('lobbyList', (lobbies) => {
			lobbyListContainer.innerHTML = '';

			if (lobbies.length > 0) {
				lobbies.forEach((lobby) => {
					let lobbyItem = document.createElement('div');
					lobbyItem.className = 'lobby-item';

					let lobbyDetails = document.createElement('span');
					lobbyDetails.innerHTML = `<strong>${lobby.name}</strong> (ID: ${lobby.lobbyId})`;
					lobbyItem.appendChild(lobbyDetails);

					let joinButton = document.createElement('button');
					joinButton.className = 'join-button';
					joinButton.innerHTML = 'Join';

					joinButton.addEventListener('click', () => {
						this.socket.emit('joinLobby', lobby.lobbyId, this.playerName);
					});

					lobbyItem.appendChild(joinButton);
					lobbyListContainer.appendChild(lobbyItem);
				});
			} else {
				lobbyListContainer.innerHTML = '<p>No available lobbies</p>';
			}
		});

		this.socket.on('message', (data) => {
			console.log(`Received message: ${data}`);
			const [message, enemyName] = data.split(';');
			if (message === 'Start') {
				this.scene.launch('Game', { socket: this.socket, playerId: this.playerId, enemyName });
				this.scene.pause('JoinLobby');
				this.scene.stop('Start');
				document.body.removeChild(lobbyListContainer);
			}
		});
	}
}
